/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  useSelector,
  // useDispatch
} from 'react-redux';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';
import { PrivateRoute, PermissionRoute, HouseholdPermissionRoute } from '..';
import {
  HouseholdAssetMap,
  HouseholdFinancials,
  ConsumerHouseholdSummary,
  HouseholdBalanceSheet,
  HouseholdPolicySummary,
  HouseholdTargetMaps,
  HouseholdTargetMap,
  HouseholdBeneficiaries,
  HouseholdSignals,
  HouseholdFamilyMap,
  HouseholdMembersList,
} from '../../containers';
import {
  Auth,
  ResetPassword,
  ConsumerDashboard,
  ConsumerHousehold,
  Preferences,
  // Notifications,
  Terms,
  HouseholdNewManual,
  HouseholdInfo,
  ConsumerInvite,
  ConsumerHouseholdReports,
  HouseholdMembers,
} from '../../pages';
// import { getUnreadCount } from '../../store/actions';
import { RouterOutlet } from '../../styles/globalStyle';
import { BASENAME as basename } from '../../utils/constants';
import { GuessTimeZone, SendTrackingEvent } from '../../utils';
import { useDispatch } from 'react-redux';
import { setInvocationId } from '../../store/actions';

/*
  Router used for all top level connect routes, rendered by EntryRouter
  - All routes go through here, household routes get sent to ConsumerHouseholdRouter from ConsumerHousehold.jsx
*/
const ConsumerRouter = () => {
  const {
    loggedIn,
    isConsumer,
    isPreview,
    userRequiresTerms,
    householdConfigs,
    consumerCanCreateHousehold,
    canEdit,
    googleAnalyticsId,
    environment,
    user,
    invocationId,
  } = useSelector((state) => ({
    loggedIn: state.user.loggedIn,
    isConsumer: state.user.isConsumer,
    isPreview: state.user.isPreview,
    userRequiresTerms: state.user.userRequiresTerms,
    consumerCanCreateHousehold: state.user.consumerCanCreateHousehold,
    canEdit: state.households.canEditHousehold,
    householdConfigs: state.households.householdConfigs,
    googleAnalyticsId: state.configs.googleAnalyticsId,
    environment: state.configs.environment,
    user: state.user.user,
    invocationId: state.configs.invocationId,
  }));
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = location;
  const [reactGALoaded, setReactGALoaded] = useState(false);

  //HOOK TO SET INVOCATION ID USED FOR ALL TRACKING EVENTS
  useEffect(() => {
    const ampux_invocation_id = uuidv4();
    dispatch(setInvocationId(ampux_invocation_id));
  }, []);

  //ONCE INVOCATION ID IS SET A SESSION TRACKING CALL IS SENT
  useEffect(() => {
    if (invocationId) {
      const object = {
        user_agent: navigator.userAgent,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        screen_width_avail: window.screen.availWidth,
        screen_height_avail: window.screen.availHeight,
        screen_width_inner: window.innerWidth,
        screen_height_inner: window.innerHeight,
        screen_width_outer: window.outerWidth,
        screen_height_outer: window.outerHeight,
        tz_name:
          Intl.DateTimeFormat().resolvedOptions().timeZone || GuessTimeZone(),
        tz_offset: new Date().getTimezoneOffset(),
      };
      SendTrackingEvent(
        invocationId,
        'action',
        'invocation',
        'session',
        object
      );
    }
  }, [invocationId]);

  //INIT GOOGLE ANALYTICS CALL
  useEffect(() => {
    if (googleAnalyticsId && user?.email && environment) {
      setReactGALoaded(true);
      ReactGA.initialize(googleAnalyticsId);
      // const ga = ReactGA.ga();
      // ga('require', 'displayFeatures');
      ReactGA.set({
        dimension1: environment,
        dimension2: 'true',
        '&uid': user.email,
        page: location.pathname,
      });
      ReactGA.send({ hitType: 'pageview' });
    }
  }, [googleAnalyticsId, user, environment]);

  //SENDS TRACKING EVENT FOR PAGE DURING ANY ROUTE CHANGE
  useEffect(() => {
    if (invocationId && loggedIn) {
      try {
        SendTrackingEvent(invocationId, 'page');
      } catch (e) {
        console.log(e);
      }
    }
    if (reactGALoaded) {
      try {
        ReactGA.set({ page: location.pathname });
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
        // ReactGA.pageview(location.pathname);
      } catch (e) {
        console.log(e);
      }
    }
    //   if (loggedIn) {
    //     dispatch(getUnreadCount());
    //   }
  }, [location, loggedIn, reactGALoaded]);

  //Redirect to terms if not accepted
  useEffect(() => {
    if (userRequiresTerms === true && !isPreview) {
      return navigate(`/user/terms`);
    }
  }, [userRequiresTerms, pathname]);

  //CHECKS IF USER IS ONLY ADVISOR TYPE - IF THEY ARE THEY GET TAKEN TO APP ROUTER
  useEffect(() => {
    if (loggedIn) {
      if (!isConsumer && !isPreview) {
        window.location = `${window.location.origin}/${basename}/member/dashboard`;
      }
    }
  }, [isConsumer, loggedIn]);

  const RedirectRoute = () => {
    return loggedIn ? <Navigate to="dashboard" /> : <Navigate to="auth" />;
  };

  const RedirectHouseholdRoute = () => {
    return loggedIn ? <Navigate to="summary" /> : <Navigate to="/" />;
  };

  //Need to create a function to check if they have access to each household route for the hasPermission

  //Need route level blocking to prevent users from accessing routes pages they don't have access to
  //Then need to determine display of sidenav icons
  //Then on each page need to determine what can be display based off permission
  //Then actions and buttons need to be changed depending on permission

  //Can either create functions for each permission (view/edit/pdf/etc)
  //Or can create function for each page that returns what can be done

  const userCanView = (route) => {
    let allowedRoutes = [];
    if (householdConfigs?.show) {
      allowedRoutes = householdConfigs?.show;
    }
    return allowedRoutes.includes(route);
  };

  const userCanPrint = (route) => {
    let allowedRoutes = [];
    if (householdConfigs?.print) {
      allowedRoutes = householdConfigs?.print;
    }
    return allowedRoutes.includes(route);
  };

  const userCanEdit = (route) => {
    switch (route) {
      case 'household':
        return false;
      case 'assetmap':
        return false;
      case 'members':
        return false;
      case 'financials':
        return false;
      case 'signals':
        return false;
      case 'targetmaps':
        return false;
      case 'beneficiaries':
        return false;
      default:
        return false;
    }
  };

  return (
    <RouterOutlet id="outlet">
      <Routes>
        <Route exact path="/" element={<RedirectRoute />} />
        {!loggedIn && (
          <Route path="auth" element={<Auth isConsumer={true} />} />
        )}
        <Route path="password-reset" element={<ResetPassword />} />
        {/* <PrivateRoute path="/notifications" component={Notifications} /> */}
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <ConsumerDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="i/:id"
          element={
            loggedIn ? (
              <ConsumerDashboard redirected={true} />
            ) : (
              <ConsumerInvite redirected={true} />
            )
          }
        />
        <Route
          path="i/:id/verify"
          element={<ConsumerInvite redirected={false} isVerify={true} />}
        />
        <Route
          path="auth/i/:id"
          element={
            loggedIn ? (
              <ConsumerDashboard redirected={true} />
            ) : (
              <Auth isConsumer={true} isShared={true} />
            )
          }
        />
        <Route
          path="household/new"
          element={
            <PermissionRoute role={consumerCanCreateHousehold}>
              <HouseholdNewManual isConsumer={true} />
            </PermissionRoute>
          }
        />
        <Route
          path="household/:id"
          element={
            <PrivateRoute>
              <ConsumerHousehold />
            </PrivateRoute>
          }
        />
        <Route
          path="user/preferences"
          element={
            <PrivateRoute>
              <Preferences isConsumer={true} />
            </PrivateRoute>
          }
        />
        <Route path="user/terms" element={<Terms isConsumer={true} />} />
        <Route
          path="user"
          element={
            <PrivateRoute checkIsDelegate={false}>
              <Preferences isConsumer={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="household/:id"
          element={
            <PrivateRoute>
              <ConsumerHousehold isConsumer={true} canEdit={canEdit} />
            </PrivateRoute>
          }
        >
          <Route
            path="summary"
            element={
              <PrivateRoute>
                <ConsumerHouseholdSummary
                  isConsumer={true}
                  canEdit={canEdit}
                  viewMembers={userCanView('members')}
                  viewSignals={userCanView('signals')}
                  viewReports={false}
                  // viewReports={userCanView('reports')}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="assetmap"
            element={
              <PrivateRoute>
                <HouseholdAssetMap
                  isConsumer={true}
                  canEdit={userCanEdit('assetmap')}
                  showPDF={userCanPrint('assetmap')}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="relationships"
            element={
              <HouseholdPermissionRoute hasPermission={userCanView('members')}>
                <HouseholdMembers
                  isConsumer={true}
                  canEdit={userCanEdit('members')}
                  showPDF={userCanPrint('members')}
                />
              </HouseholdPermissionRoute>
            }
          >
            <Route
              path="map"
              element={
                <HouseholdPermissionRoute
                  hasPermission={userCanView('members')}
                  redirectPath={'..'}
                >
                  <HouseholdFamilyMap
                    isConsumer={true}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              index
              element={
                <HouseholdPermissionRoute
                  hasPermission={userCanView('members')}
                >
                  <HouseholdMembersList
                    isConsumer={true}
                    canEdit={userCanEdit('members')}
                    showPDF={userCanPrint('members')}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route path="*" element={<Navigate to="" />} />
          </Route>
          <Route path="members" element={<Navigate to="../relationships" />} />
          <Route
            path="members/map"
            element={<Navigate to="../relationships/map" />}
          />
          <Route
            path="financials"
            element={
              <HouseholdPermissionRoute
                hasPermission={userCanView('financials')}
              >
                <HouseholdFinancials
                  isConsumer={true}
                  canEdit={userCanEdit('financials')}
                  showPDF={userCanPrint('financials')}
                />
              </HouseholdPermissionRoute>
            }
          />
          <Route
            path="signals"
            element={
              <HouseholdPermissionRoute hasPermission={userCanView('signals')}>
                <HouseholdSignals
                  isConsumer={true}
                  canEdit={userCanEdit('signals')}
                  showPDF={userCanPrint('signals')}
                />
              </HouseholdPermissionRoute>
            }
          />
          <Route
            path="targetmap/:tm_id"
            element={
              <HouseholdPermissionRoute
                hasPermission={userCanView('targetmap')}
              >
                <HouseholdTargetMap
                  isConsumer={true}
                  canEdit={userCanEdit('targetmap')}
                  showPDF={userCanPrint('targetmap')}
                />
              </HouseholdPermissionRoute>
            }
          />
          <Route
            path="targetmap"
            element={
              <HouseholdPermissionRoute
                hasPermission={userCanView('targetmap')}
              >
                <HouseholdTargetMaps
                  isConsumer={true}
                  canEdit={userCanEdit('targetmap')}
                  showPDF={userCanPrint('targetmap')}
                />
              </HouseholdPermissionRoute>
            }
          />
          <Route
            path="balance_sheet"
            element={
              <HouseholdPermissionRoute
                hasPermission={userCanView('balance_sheet')}
              >
                <HouseholdBalanceSheet
                  isConsumer={true}
                  canEdit={userCanEdit('balance_sheet')}
                  showPDF={userCanPrint('balance_sheet')}
                />
              </HouseholdPermissionRoute>
            }
          />
          <Route
            path="policy_summary"
            element={
              <HouseholdPermissionRoute
                hasPermission={userCanView('policy_summary')}
              >
                <HouseholdPolicySummary
                  isConsumer={true}
                  canEdit={userCanEdit('policy_summary')}
                  showPDF={userCanPrint('policy_summary')}
                />
              </HouseholdPermissionRoute>
            }
          />
          <Route
            path="beneficiaries"
            element={
              <HouseholdPermissionRoute
                hasPermission={userCanView('beneficiaries')}
              >
                <HouseholdBeneficiaries
                  isConsumer={true}
                  canEdit={userCanEdit('beneficiaries')}
                  showPDF={userCanPrint('beneficiaries')}
                />
              </HouseholdPermissionRoute>
            }
          />
          <Route
            path="reports"
            element={
              <HouseholdPermissionRoute hasPermission={userCanView('reports')}>
                <ConsumerHouseholdReports
                  isConsumer={true}
                  canEdit={false}
                  showPDF={userCanPrint('reports')}
                />
              </HouseholdPermissionRoute>
            }
          />
          <Route
            path="info"
            element={
              <HouseholdPermissionRoute hasPermission={canEdit}>
                <HouseholdInfo isConsumer={true} />
              </HouseholdPermissionRoute>
            }
          />
          <Route index element={<RedirectHouseholdRoute />} />
          <Route path="*" element={<RedirectHouseholdRoute />} />
        </Route>
        <Route path="*" element={<RedirectRoute />} />
      </Routes>
    </RouterOutlet>
  );
};

export default ConsumerRouter;
