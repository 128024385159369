/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import {
  Background,
  Controls,
  ReactFlow,
  addEdge,
  // useNodesState,
  // useEdgesState,
  Panel,
  NodeToolbar,
  // NodeResizer,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  // MiniMap,
  // ControlButton,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {
  CapitalizeFirstLetter,
  DetectEnterKeyPress,
  GenerateAge,
  GenerateAvatarMemberIcon,
  GenerateRelationshipDisplay,
  HtmlToString,
  SendTrackingEvent,
  ShowMemberAge,
  UseDoubleClick,
  UseOutsideClick,
} from '../../utils';
import {
  boxShadows,
  colors,
  fonts,
  heights,
  inputColors,
} from '../../styles/variables';
import {
  ConnectorEdge,
  MemberNode,
  JoinNode,
  LoadingAnimation,
  Error,
  SimpleModal,
  Success,
} from '../../components';
import {
  clearMemberInfo,
  createFamilyMap,
  getFamilyMap,
  getFamilyMaps,
  toggleShowDeleteModal,
  toggleShowEntityModal,
  toggleShowMemberModal,
  updateFamilyMap,
  updateHouseholdStoreValue,
} from '../../store/actions';
import {
  addEntity,
  addText,
  check,
  chevronDown,
  plusCircleDark,
  save,
  swap,
  trashDark,
  userAdd,
} from '../../assets';
import { ButtonThemes, ErrorThemes, SuccessThemes } from '../../styles/themes';
import {
  FlexCenterAll,
  FlexCenterStart,
} from '../../styles/library/layoutStyles';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import TextNode from '../../components/Family Map/TextNode';

const HouseholdFamilyMap = ({ isConsumer }) => {
  const nameRef = useRef();
  const inputRef = useRef();
  const menuRef = useRef();
  const dispatch = useDispatch();
  const { householdId } = useOutletContext();
  const {
    currentHousehold,
    familyMaps,
    familyMap,
    updatedFamilyMap,
    justDeletedFamilyMap,
    familyMapError,
    sideNavWidth,
    allMemberCategories,
    invocationId,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    familyMaps: state.households.familyMaps,
    familyMap: state.households.familyMap,
    updatedFamilyMap: state.households.updatedFamilyMap,
    justDeletedFamilyMap: state.households.justDeletedFamilyMap,
    familyMapError: state.households.familyMapError,
    sideNavWidth: state.user.sideNavWidth,
    allMemberCategories: state.configs.allMemberCategories,
    invocationId: state.configs.invocationId,
  }));
  const [loadedFamilyMap, setLoadedFamilyMap] = useState(false);
  const [currentMap, setCurrentMap] = useState({
    name: 'First Map',
    default: false,
    tree: {},
  });
  const [membersList, setMembersList] = useState([]);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [dockedMembers, setDockedMembers] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [mapName, setMapName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [isUpdatingMap, setIsUpdatingMap] = useState(false);
  const [showChangeMaps, setShowChangeMaps] = useState(false);
  const [showMapsList, setShowMapsList] = useState(false);
  const [mapsList, setMapsList] = useState([]);
  const [resetZoom, setResetZoom] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unknown Error');
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [mapToChangeTo, setMapToChangeTo] = useState(null);
  const [isExpandedMembers, setIsExpandedMembers] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState();
  // const [successMessage, setSuccessMessage] = useState(null);

  //Determine which row based off category
  const verticalMapping = {
    grandparent: -2,
    parent: -1,
    primary: 0,
    spouse: 0,
    partner: 0,
    sibling: 0,
    child: 1,
    pet: 1,
    grandchild: 2,
  };
  // const docked = [
  //   'employer',
  //   'accountant',
  //   'attorney',
  //   'busprof',
  //   'family',
  //   'friend',
  //   'trustee',
  //   'other',
  //   'employee',
  // ];

  //TELLS REACT-FLOW WHICH CUSTOM NODES TO USE
  const nodeTypes = useMemo(
    () => ({ member: MemberNode, join: JoinNode, text: TextNode }),
    []
  );
  //TELLS REACT-FLOW WHICH CUSTOM EDGES TO USE
  const edgeTypes = useMemo(() => ({ 'custom-edge': ConnectorEdge }), []);

  //STOP SHOWING CHANGE FAMILY MAP LIST ON CLICK OUTSIDE
  UseOutsideClick(menuRef, () => {
    if (showMapsList) {
      setShowMapsList(false);
    }
  });

  //DOUBLE CLICK MAP NAME TO EDIT
  UseDoubleClick({
    onDoubleClick: (e) => {
      setIsEditingName(true);
    },
    ref: nameRef,
  });

  //GET ALL FAMILY MAPS ON PAGE INIT
  useEffect(() => {
    dispatch(getFamilyMaps(householdId));
  }, []);

  //ERROR HANDLING AND DISPLAYS MESSAGE AS ERROR BOX AT TOP OF MAP
  useEffect(() => {
    if (familyMapError) {
      resetLoading(true);
      setShowError(true);
      const errorData = familyMapError?.data;
      let errorMessage = 'Unknown Error';
      let action = 'creating';
      if (familyMapError?.action) {
        action = familyMapError?.action;
        errorMessage = `Error ${action} Map.`;
      }
      if (errorData) {
        let errors = [];
        for (let [key, value] of Object.entries(errorData)) {
          errors.push({ field: key, message: value });
        }
        errorMessage = errors.map((error) => {
          if (error.field === 'non_field_errors') {
            return `${error.message}`;
          }
          let fieldName = error.field.replaceAll('_', ' ');
          fieldName = CapitalizeFirstLetter(fieldName);
          return `${fieldName}: ${error.message}`;
        });
      }
      setErrorMessage(errorMessage);
    }
  }, [familyMapError]);

  //IF NO SAVED MAPS CREATES DEFAULT OR GETS DEFAULT OR FIRST SAVED FAMILY MAP
  useEffect(() => {
    if (familyMaps && loadedMembers) {
      if (Array.isArray(familyMaps) && familyMaps.length === 0 && !hasLoaded) {
        setShowChangeMaps(false);
        const defaultNodes = generateDefaultNodes();
        setCurrentMap({
          name: 'First Map',
          default: false,
          tree: { nodes: defaultNodes, edges: [] },
        });
        setNodes(defaultNodes);
      } else {
        const filteredMaps = familyMaps.filter(
          (map) => map.id !== currentMap.id
        );
        setMapsList(filteredMaps);
        setShowChangeMaps(familyMaps.length > 1);
        const defaultMap = familyMaps.find((map) => map.default);
        if (defaultMap) {
          !hasLoaded && setCurrentMap(defaultMap);
        } else {
          const matchingMap = familyMaps[0];
          !hasLoaded && setCurrentMap(matchingMap);
        }
      }
      setHasLoaded(true);
    }
  }, [
    familyMaps?.length,
    familyMaps,
    loadedMembers,
    updatedFamilyMap,
    currentMap?.id,
  ]);

  //SETS FAMILY MAP FROM STORE LOCALLY IN COMPONENT
  useEffect(() => {
    if (familyMap) {
      setCurrentMap(familyMap);
      setMapName(familyMap?.name);
      resetErrorDisplay();
      resetLoading();
    }
  }, [familyMap]);

  //TAKES SAVED NODES AND EDGES AND TURNS THEM INTO LOCAL LIST, AS WELL AS UPDATING MEMBER DISPLAY
  useEffect(() => {
    if (currentMap && loadedMembers && hasLoaded) {
      setMapName(currentMap?.name);
      let updatedNodes = [];
      if (Array.isArray(currentMap?.tree?.nodes)) {
        const transformedNodes = transformMemberNodes(currentMap?.tree?.nodes);
        updatedNodes = transformedNodes;
      }
      setNodes(updatedNodes);
      setEdges(currentMap?.tree?.edges ? currentMap?.tree?.edges : []);
      setLoadedFamilyMap(true);
      if (resetZoom) {
        setResetZoom(false);
        setTimeout(() => {
          reactFlowInstance.fitView();
        }, 10);
      }
    }
  }, [currentMap, loadedMembers]);

  //CREATES LIST OF MEMBERS THAT CAN BE USED IN THE FAMILY MAP
  useEffect(() => {
    if (currentHousehold?.allHouseholdMembers) {
      const members = currentHousehold?.allHouseholdMembers?.map((mem) => {
        const memRole = GenerateRelationshipDisplay(mem, allMemberCategories);
        mem.age = GenerateAge(mem.date_of_birth);
        mem.name = HtmlToString(mem.name);
        mem.role = HtmlToString(memRole);
        mem.order = verticalMapping[mem.category];
        mem.isMember = mem.member_type === 'member';
        return mem;
      });
      setMembersList(members);
      setLoadedMembers(true);
    }
  }, [currentHousehold?.allHouseholdMembers]);

  //IF MEMBERS CHANGE (EDIT/DELETE/NEW) UPDATE THE NODE VALUES
  useEffect(() => {
    if (loadedFamilyMap && loadedMembers) {
      const transformedNodes = transformMemberNodes([...nodes]);
      setNodes(transformedNodes);
    }
  }, [loadedFamilyMap, membersList]);

  //CHECKS TO SEE WHICH MEMBERS ARE NOT ON MAP AND KEEPS THEM IN DOCK
  useEffect(() => {
    if (nodes && loadedFamilyMap && loadedMembers) {
      const nodeIds = nodes.map((node) => node.id);
      setDockedMembers(
        membersList.filter((member) => !nodeIds.includes(member.id))
      );
    }
  }, [nodes, loadedFamilyMap, membersList]);

  //HANDLES UPDATING OF FAMILY MAP
  useEffect(() => {
    if (updatedFamilyMap) {
      if (mapToChangeTo) {
        mapOnClick(mapToChangeTo, true);
      }
      resetLoading();
      resetErrorDisplay();
      dispatch(getFamilyMaps(householdId));
      dispatch(updateHouseholdStoreValue('updatedFamilyMap', false));
    }
  }, [updatedFamilyMap]);

  //HANDLES DELETING OF FAMILY MAP
  useEffect(() => {
    if (justDeletedFamilyMap) {
      dispatch(updateHouseholdStoreValue('justDeletedFamilyMap', false));
      setHasLoaded(false);
      setResetZoom(true);
      sendFamilyMapTrackingEvent('action', 'family_map_delete', {
        family_map: justDeletedFamilyMap,
      });
    }
  }, [justDeletedFamilyMap]);

  // const [nodes, setNodes, onNodesChange] = useNodesState(nodesList);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (connection) => {
      setHasChanges(true);
      const edge = { ...connection, type: 'custom-edge' };
      setEdges((eds) => addEdge(edge, eds));
    },
    [setEdges]
  );

  const trackedChanges = ['remove', 'position'];
  const onNodesChange = useCallback((changes) => {
    const trackedChange = changes.find((action) =>
      trackedChanges.includes(action?.type)
    );
    if (trackedChange) {
      setHasChanges(true);
    }
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    const remove = changes.find((action) => action?.type === 'remove');
    if (remove) {
      setHasChanges(true);
    }
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  //FUNCTION TO RESET LOADING
  const resetLoading = (hasLoaded = false) => {
    setIsEditingName(false);
    setHasChanges(false);
    setMapToChangeTo(null);
    if (hasLoaded) {
      setLoadedFamilyMap(true);
    }
    setTimeout(() => {
      setIsUpdatingMap(false);
    }, 1000);
  };

  const resetErrorDisplay = () => {
    setShowError(false);
    setErrorMessage('Unknown Error');
  };

  //GETS UP-TO-DATE MEMBER INFO AND REMOVES NODES IF MEMBER DOESN'T EXIST
  const transformMemberNodes = (nodes) => {
    return nodes.reduce((acc, node) => {
      const member = { ...node };
      if (node.type === 'member') {
        const matchingMember = membersList.find((mem) => mem.id === node.id);
        if (matchingMember) {
          member.data = matchingMember;
          return [...acc, member];
        }
      } else {
        return [...acc, node];
      }
      return acc;
    }, []);
  };

  const getRowMembers = (members, location) => {
    let transformedMembers = members.filter((mem) => mem.order === location);
    return transformedMembers.map((mem, index) => {
      mem.xAxis = 200 * index;
      mem.yAxis = mem.order * 150;
      return mem;
    });
  };

  //Need to go over list of members and determine their (x,y) as well as docked position
  const generateDefaultNodes = () => {
    if (loadedMembers) {
      const levels = [-2, -1, 0, 1, 2];
      let memberLevels = levels.reduce((acc, level) => {
        const levelMembers = getRowMembers(membersList, level);
        return [...acc, levelMembers];
      }, []);
      memberLevels = memberLevels.flat();
      const nodes = [...memberLevels].map((mem) => {
        return generateMemberNode(mem);
      });
      return [...nodes, joinNode];
    }
  };

  const generateMemberNode = (member, isNew = false) => {
    const { yAxis, xAxis } = member;
    return {
      id: member.id,
      position: {
        x: xAxis ? xAxis : 0,
        y: yAxis ? yAxis : 0,
      },
      data: { ...member, isNew: isNew },
      type: 'member',
    };
  };

  const addMemberNode = (member) => {
    const memberNodes = nodes.filter((node) => node.type === 'member');
    if (memberNodes?.length) {
      const maxNode = memberNodes.reduce((prev, current) =>
        prev && prev.num > current.num ? prev : current
      );
      const { position, width } = maxNode;
      member.xAxis = position?.x + width + 20;
      member.yAxis = position?.y;
    }
    setHasChanges(true);
    const newNode = generateMemberNode(member, true);
    setNodes([...nodes, newNode]);
  };

  const joinNode = {
    id: 'join-1',
    position: { x: -200, y: 0 },
    data: { label: 'Join', id: 'join-1' },
    type: 'join',
    num: 1,
  };

  const addConnectNode = () => {
    const connectNodes = nodes.filter((node) => node.type === 'join');
    let newNode = joinNode;
    if (connectNodes?.length) {
      const maxNode = connectNodes.reduce((prev, current) =>
        prev && prev.num > current.num ? prev : current
      );
      const { position, width } = maxNode;
      const newId = maxNode.num + 1;
      const textId = `join-${newId.toString()}`;
      newNode = {
        ...maxNode,
        id: textId,
        num: newId,
        selected: false,
        position: { x: position?.x + width + 20, y: position?.y },
        data: { isNew: true, id: textId },
      };
    }
    setNodes([...nodes, newNode]);
    setHasChanges(true);
    // setSuccessMessage('Connection Added');
    // setTimeout(() => {
    //   setSuccessMessage(null);
    // }, 1000);
  };

  const textNode = {
    id: 'text-1',
    position: { x: 200, y: 0 },
    data: { label: 'Click to Edit', id: 'text-1' },
    type: 'text',
    num: 1,
  };

  const addTextNode = () => {
    const textNodes = nodes.filter((node) => node.type === 'text');
    let newNode = textNode;
    if (textNodes?.length) {
      const maxNode = textNodes.reduce((prev, current) =>
        prev && prev.num > current.num ? prev : current
      );
      const { position, width } = maxNode;
      console.log('maxNode', maxNode);
      const newId = maxNode.num + 1;
      const textId = `text-${newId.toString()}`;
      newNode = {
        ...textNode,
        id: textId,
        num: newId,
        selected: false,
        position: { x: position?.x + width + 20, y: position?.y },
        data: { ...textNode.data, id: textId, isNew: true },
      };
    }
    setNodes([...nodes, newNode]);
    setHasChanges(true);

    // setSuccessMessage('Text Box Added');
    // setTimeout(() => {
    //   setSuccessMessage(null);
    // }, 1000);
  };

  const updateMapName = () => {
    if (isEditingName) {
      const originalName = currentMap?.name.trim();
      const newName = mapName.trim();
      const changedName = originalName !== newName;
      if (changedName) {
        setIsUpdatingMap(true);
        setIsEditingName(false);
        const data = {
          ...currentMap,
          name: newName,
          tree: { nodes: nodes, edges: edges },
        };
        sendFamilyMapTrackingEvent('action', 'family_map_edit', {
          family_map: data,
        });
        if (currentMap?.id) {
          return dispatch(updateFamilyMap(householdId, data));
        } else {
          setResetZoom(true);
          return dispatch(createFamilyMap(householdId, data));
        }
      }
    }
  };

  const mapOnClick = (map, ignoreChanges = false) => {
    if (hasChanges && !ignoreChanges) {
      setMapToChangeTo(map);
      return setShowSaveChanges(true);
    }
    dispatch(getFamilyMap(householdId, map?.id));
    setShowMapsList(false);
    setResetZoom(true);
    sendFamilyMapTrackingEvent('click', 'family_map_select', {
      family_map: map?.id,
    });
  };

  const createNewFamilyMap = () => {
    const data = {
      name: 'New Map',
      default: false,
      tree: { nodes: generateDefaultNodes(), edges: [] },
    };
    sendFamilyMapTrackingEvent('action', 'family_map_create', {
      family_map: data,
    });
    setResetZoom(true);
    dispatch(createFamilyMap(householdId, data));
  };

  const updateFamilyMapOnClick = () => {
    setIsUpdatingMap(true);
    const data = {
      ...currentMap,
      tree: { nodes: nodes, edges: edges },
    };
    sendFamilyMapTrackingEvent(
      'action',
      currentMap?.id ? 'family_map_edit' : 'family_map_create',
      {
        family_map: data,
      }
    );
    if (currentMap?.id) {
      return dispatch(updateFamilyMap(householdId, data));
    } else {
      return dispatch(createFamilyMap(householdId, data));
    }
  };

  const deleteFamilyMapOnClick = () => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'family_map',
        currentMap?.name,
        currentMap?.id
      )
    );
  };

  const sendFamilyMapTrackingEvent = (action = 'click', verb, data) => {
    return SendTrackingEvent(
      invocationId,
      action,
      verb,
      'household_family_map',
      data
    );
  };

  const addMember = () => {
    dispatch(clearMemberInfo());
    dispatch(toggleShowMemberModal(true));
  };

  const addEntityOnClick = () => {
    dispatch(clearMemberInfo());
    dispatch(toggleShowEntityModal(true));
  };

  const generateWarningContent = () => {
    return {
      heading: 'Save Changes?',
      text: `You are leaving a map without saving changes. Would you like to save changes before you leave?`,
      buttons: [
        {
          text: 'No',
          function: () => {
            setHasChanges(false);
            setShowSaveChanges(false);
            mapOnClick(mapToChangeTo, true);
          },
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes',
          theme: ButtonThemes.primary,
          function: () => {
            updateFamilyMapOnClick();
            setHasChanges(false);
            setShowSaveChanges(false);
          },
        },
      ],
    };
  };

  return (
    <>
      {showSaveChanges && (
        <SimpleModal
          hide={() => setShowSaveChanges(!showSaveChanges)}
          content={generateWarningContent()}
        />
      )}
      <PageContainer $sideNavWidth={sideNavWidth}>
        {!loadedFamilyMap && (
          <LoadingContainer>
            <LoadingAnimation />
          </LoadingContainer>
        )}
        <FlowContainer>
          <ReactFlowProvider>
            <ReactFlow
              nodes={nodes}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              edges={edges}
              edgeTypes={edgeTypes}
              connectionMode="loose"
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              snapToGrid
              snapGrid={[10, 10]}
              proOptions={{ hideAttribution: true }}
              fitView
              deleteKeyCode={['Delete', 'Backspace']}
              onInit={(instance) => setReactFlowInstance(instance)}
            >
              <Background variant={'dots'} />
              <Controls>
                {/* <ControlButton onClick={onSave}>
              <img src={save} alt="save" style={{ width: '12px' }} />
            </ControlButton> */}
              </Controls>
              <NodeToolbar />
              {/* <NodeResizer /> */}
              {/* <MiniMap
                nodeStrokeWidth={3}
                zoomable
                pannable
                position="top-right"
              /> */}
              {showError && (
                <Panel position="top-center">
                  <Error
                    theme={ErrorThemes.inverted}
                    errorMessage={errorMessage}
                    dismissOnClick={resetErrorDisplay}
                  />
                </Panel>
              )}
              {isUpdatingMap && (
                <Panel position="top-center">
                  <Success
                    successMessage={'Saved Changes'}
                    theme={SuccessThemes.inline}
                  />
                </Panel>
              )}
              <Panel position="bottom-center">
                <ActionBarContainer $sideNavWidth={sideNavWidth}>
                  <MemberDockContainer>
                    <ExpandContainer
                      onClick={() => setIsExpandedMembers(!isExpandedMembers)}
                      $expanded={isExpandedMembers}
                    >
                      <img src={chevronDown} alt="expand" />
                    </ExpandContainer>
                    {dockedMembers?.length ? (
                      dockedMembers.map((member) => {
                        return (
                          <MemberContainer
                            key={member?.id}
                            onClick={() => addMemberNode(member)}
                            $expanded={isExpandedMembers}
                          >
                            {GenerateAvatarMemberIcon(
                              member.category,
                              member.isMember
                            )}
                            <MemberInfo>
                              <MemberName>
                                {member?.name}

                                {ShowMemberAge(member) && (
                                  <MemberAge>
                                    {`(${member.age}${member.date_of_birth_estimated ? 'e' : ''})`}
                                  </MemberAge>
                                )}
                              </MemberName>
                              {isExpandedMembers && (
                                <MemberCategory>{member.role}</MemberCategory>
                              )}
                            </MemberInfo>
                          </MemberContainer>
                        );
                      })
                    ) : loadedFamilyMap ? (
                      <EmptyMessage>All Members on Map</EmptyMessage>
                    ) : (
                      [10, 1, 5, 20, 2, 15].map((member) => {
                        return (
                          <MemberContainer key={member}>
                            <LoadingPlaceholder
                              round={true}
                              width="16px"
                              height="16px"
                              margin="0"
                            />
                            <MemberInfo>
                              <LoadingPlaceholder
                                width={`${70 + member}px`}
                                height="16px"
                                margin="0 4px 0 0"
                              />
                            </MemberInfo>
                          </MemberContainer>
                        );
                      })
                    )}
                  </MemberDockContainer>
                  <ActionBarBottom>
                    <LeftContent>
                      <NameContainer>
                        <NameInput
                          ref={inputRef}
                          type="text"
                          maxLength="56"
                          value={mapName}
                          name="name"
                          onChange={(e) => setMapName(e.currentTarget.value)}
                          onBlur={() => updateMapName()}
                          onKeyPress={(e) =>
                            DetectEnterKeyPress(e, updateMapName)
                          }
                          visible={isEditingName}
                        />
                        <Name
                          ref={nameRef}
                          visible={!isEditingName && loadedFamilyMap}
                          title={`${currentMap?.name} (Double click to edit)`}
                        >
                          {currentMap?.name}
                        </Name>
                        {isUpdatingMap && (
                          <span style={{ marginLeft: '5px' }}>
                            <LoadingAnimation
                              dots={true}
                              smaller={true}
                              color={colors.green}
                            />
                          </span>
                        )}
                      </NameContainer>
                    </LeftContent>
                    <CenterContent>
                      <ActionContainer onClick={addConnectNode}>
                        <ActionIcon
                          src={plusCircleDark}
                          alt={'connect'}
                          data-image={'connection'}
                          width={'15px'}
                        />
                        <ActionText>Connection</ActionText>
                      </ActionContainer>
                      <ActionContainer onClick={addTextNode}>
                        <ActionIcon
                          src={addText}
                          alt={'text'}
                          data-image={'add-text'}
                          width={'15px'}
                        />
                        <ActionText>Text</ActionText>
                      </ActionContainer>
                      <ActionContainer onClick={addMember}>
                        <ActionIcon
                          src={userAdd}
                          alt={'member'}
                          data-image={'add-member'}
                          width={'18px'}
                        />
                        <ActionText>Member</ActionText>
                      </ActionContainer>
                      <ActionContainer onClick={addEntityOnClick}>
                        <ActionIcon
                          src={addEntity}
                          alt={'entity'}
                          data-image={'add-entity'}
                          width={'20px'}
                        />
                        <ActionText>Entity</ActionText>
                      </ActionContainer>
                    </CenterContent>
                    <RightContent>
                      {showChangeMaps && (
                        <ListContainer>
                          <ActionContainer
                            onClick={() => setShowMapsList(true)}
                          >
                            <ActionIcon
                              src={swap}
                              alt=" map"
                              data-image="family-map-select"
                              width="15px"
                            />
                            <ActionText>Change Map</ActionText>
                          </ActionContainer>
                          {showMapsList && (
                            <MapListContainer ref={menuRef}>
                              {mapsList.map((map, index) => {
                                return (
                                  <MapItem
                                    onClick={() => mapOnClick(map)}
                                    key={index}
                                    isLast={mapsList.length === index + 1}
                                  >
                                    <p>{map?.name}</p>
                                  </MapItem>
                                );
                              })}
                            </MapListContainer>
                          )}
                        </ListContainer>
                      )}
                      {currentMap?.id && familyMaps.length < 10 && (
                        <ActionContainer onClick={createNewFamilyMap}>
                          <ActionIcon
                            src={plusCircleDark}
                            alt={'new'}
                            data-image={'new'}
                            width={'14px'}
                          />
                          <ActionText>New</ActionText>
                        </ActionContainer>
                      )}
                      <ActionContainer
                        onClick={updateFamilyMapOnClick}
                        $margin={isUpdatingMap ? '0 9px' : null}
                      >
                        <ActionIcon
                          src={isUpdatingMap ? check : save}
                          alt={isUpdatingMap ? 'saved' : 'save'}
                          data-image={isUpdatingMap ? 'saved' : 'save'}
                          width={'13px'}
                        />
                        <ActionText
                          $color={isUpdatingMap ? colors.green : null}
                          $width={isUpdatingMap ? '28px' : '26px'}
                        >
                          Save{isUpdatingMap ? 'd' : ''}
                        </ActionText>
                      </ActionContainer>
                      {currentMap?.id && (
                        <ActionContainer onClick={deleteFamilyMapOnClick}>
                          <ActionIcon
                            src={trashDark}
                            alt={'delete'}
                            data-image={'delete'}
                            width={'15px'}
                          />
                          <ActionText>Delete</ActionText>
                        </ActionContainer>
                      )}
                    </RightContent>
                  </ActionBarBottom>
                </ActionBarContainer>
              </Panel>
            </ReactFlow>
          </ReactFlowProvider>
        </FlowContainer>
      </PageContainer>
    </>
  );
};

const LoadingContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, 0);
  z-index: 1;
`;

const PageContainer = styled.div`
  @media (max-width: 1120px) {
    height: calc(100vh - 102px - ${heights.navBar});
  }
  @media (max-width: 673px) {
    height: calc(100vh - 118px - ${heights.navBar});
  }
  flex: 1 1 auto;
  height: calc(100vh - 88px - ${heights.navBar});
  position: fixed;
  left: ${(props) => props.$sideNavWidth};
  width: ${(props) => `calc(100vw - ${props.$sideNavWidth})`};
`;

const FlowContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const MemberAge = styled.span`
  margin-left: 3px;
  font-size: 11px;
  opacity: 0.6;
  colors: ${colors.paleGrey};
  font-weight: ${fonts.light};
`;

const ExpandContainer = styled.div`
  position: fixed;
  z-index: 8;
  top: 0;
  transform: translateX(-50%);
  transform: translateY(-50%);
  right: 50%;
  background: ${colors.white};
  height: 22px;
  width: 22px;
  border-radius: 100%;
  border: thin solid ${colors.lightGrey};
  /* box-shadow: ${boxShadows.boxShadowModal}; */
  ${FlexCenterAll};
  cursor: pointer;
  img {
    width: 13px;
    height: 13px;
    flex: 0 0 auto;
    transform: ${(props) => (props.$expanded ? null : 'rotate(180deg)')};
  }
`;

const EmptyMessage = styled.p`
  text-align: center;
  flex: 1 1 auto;
  color: ${colors.paleGrey};
`;

const ActionBarContainer = styled.div`
  width: ${(props) => `calc(100vw - ${props.$sideNavWidth} - 110px)`};
  background: ${colors.white};
  color: ${colors.darkGrey};
  border: 1px solid ${colors.lightGrey};
  box-shadow: ${boxShadows.boxShadowSoft};
  border-radius: 4px;
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  justify-content: center;
  position: relative;
`;

const MemberDockContainer = styled.div`
  /* height: 20px; */
  display: flex;
  border-bottom: thin solid ${colors.lightGrey};
  padding: 15px 10px 15px 10px;
  background: ${colors.lighterGrey};
  overflow-x: auto;
  position: relative;
  border-radius: 4px 4px 0 0;
`;

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const MemberName = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 14px;
`;

const MemberCategory = styled.p`
  font-size: 11px;
  color: ${colors.paleGrey};
  text-transform: capitalize;
`;

const MemberContainer = styled.div`
  flex: 0 0 auto;
  margin: 0 5px;
  cursor: pointer;
  color: ${colors.darkGrey};
  border: ${(props) =>
    props.$expanded
      ? `1px solid ${colors.darkGrey}`
      : `1px solid ${colors.lightGrey}`};
  width: ${(props) => (props.$expanded ? 'auto' : 'auto')};
  margin: ${(props) => (props.$expanded ? '0 5px' : '0 3px')};
  background: ${(props) => (props.$expanded ? 'white' : 'white')};
  font-size: ${(props) => (props.$expanded ? '14px' : '12px')};
  border-radius: ${(props) => (props.$expanded ? '5px' : '5px')};
  padding: ${(props) => (props.$expanded ? '10px 20px 10px 10px' : '6px')};
  ${FlexCenterStart};
  img,
  svg {
    width: ${(props) => (props.$expanded ? '20px' : '12px')};
    height: ${(props) => (props.$expanded ? '20px' : '12px')};
    flex: 0 0 auto;
  }
  ${MemberName} {
    font-size: ${(props) => (props.$expanded ? '13px' : '12px')};
    font-weight: ${(props) =>
      props.$expanded ? fonts.semiBold : fonts.regular};
  }
  ${MemberInfo} {
    margin-left: ${(props) => (props.$expanded ? '9px' : '4px')};
  }
  &:hover {
    box-shadow: ${boxShadows.boxShadowAMRSelected};
  }
`;

const ActionBarBottom = styled.div`
  min-height: 25px;
  max-height: 75px;
  color: ${colors.darkGrey};
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding: 10px 15px;
`;

const LeftContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  @media (max-width: 1080px) {
    flex: 1 1 20%;
  }
  flex: 1 1 35%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const NameContainer = styled.div`
  flex: 1 1 250px;
  color: ${colors.darkGrey};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const Name = styled.span`
  @media (max-width: 1400px) {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 1100px) {
    max-width: 250px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 1010px) {
    max-width: 200px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 800px) {
    max-width: 180px;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  font-weight: ${fonts.semiBold};
  margin: 0 10px 0 5px;
  font-size: 15px;
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: ${(props) => (props.visible ? '2px 0' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  width: ${(props) => (props.visible ? 'auto' : '0px')};
  height: ${(props) => (props.visible ? 'auto' : '0px')};
`;

const NameInput = styled.input`
  @media (max-width: 1100px) {
    font-size: 12px;
  }
  border: 1px solid ${inputColors.border};
  border-radius: 3px;
  margin: -2px 0;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  flex: ${(props) => (props.visible ? '1 1 250px' : '0 0 auto')};
  width: ${(props) => (props.visible ? 'auto' : 0)};
  padding: ${(props) => (props.visible ? '4px 8px' : '0')};
`;

const CenterContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 30%;
  }
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  flex: 1 1 40%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

const RightContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  flex: 1 1 35%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
`;

const ListContainer = styled.div`
  position: relative;
`;

const MapItem = styled.div`
  cursor: pointer;
  padding: 6px 3px;
  border-bottom: ${(props) =>
    props.isLast ? null : `1px solid ${colors.lightGrey}`};
  p {
    white-space: nowrap;
    font-size: 11px;
    font-weight: ${fonts.semiBold};
    width: 124px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const fadeInMenu = keyframes`
  from {
    bottom: 23px;
  }
  to {
    bottom: 35px;
  }
  `;

const MapListContainer = styled.div`
  @media (max-width: 890px) {
    bottom: 40px;
  }
  position: absolute;
  /* left: 0; */
  left: 50%;
  bottom: 35px;
  width: 100%;
  min-width: 130px;
  /* width: calc(100% - 16px); */
  text-align: left;
  background: white;
  color: ${colors.darkGrey};
  border: 1px solid ${colors.lightGrey};
  box-shadow: ${boxShadows.boxShadowNavbarAlt};
  padding: 3px 6px;
  border-radius: 3px;
  z-index: 6;
  /* display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; */
  transform: scale(1) translateX(-50%);
  animation: ${fadeInMenu} 0.2s ease-in-out;
`;

const ActionContainer = styled.div`
  @media (max-width: 1290px) {
    margin: 0 8px;
    width: ${(props) => (props.width ? '85px' : null)};
  }
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: ${(props) => (props.$margin ? props.$margin : '0 10px')};
  width: ${(props) => props.width};
  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
`;

const ActionText = styled.p`
  @media (max-width: 1290px) {
    font-size: 10px;
  }
  font-size: 12px;
  font-weight: ${fonts.regular};
  width: ${(props) => (props.$width ? props.$width : 'auto')};
  color: ${(props) => (props.$color ? props.$color : colors.darkGrey)};
`;

const ActionIcon = styled.img`
  @media (max-width: 1290px) {
    width: 11px;
    height: 11px;
  }
  width: ${(props) => (props.width ? props.width : '13px')};
  height: ${(props) => (props.width ? props.width : '13px')};
  margin-right: 5px;
`;

HouseholdFamilyMap.propTypes = {
  isConsumer: PropTypes.bool,
  householdId: PropTypes.string,
};

export default HouseholdFamilyMap;
