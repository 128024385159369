/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PageHeader } from '../../components';
import {
  headerContent,
  DownloadReport,
  SendTrackingEvent,
  ErrorNotification,
  UpdatePageTitle,
} from '../../utils';
import { bars, cardGrid } from '../../assets';

const HouseholdMembers = ({ isConsumer, canEdit = true, showPDF }) => {
  UpdatePageTitle('Relationships');
  const params = useParams();
  const householdId = params.id;
  const navigate = useNavigate();
  const location = useLocation();
  const { hasFamilyMap, invocationId } = useSelector((state) => ({
    hasFamilyMap: state.user.hasFamilyMap,
    invocationId: state.configs.invocationId,
  }));
  const [activeView, setActiveView] = useState('list');
  const isFamilyMap = useMatch('/household/:id/relationships/map');

  useEffect(() => {
    if (location) {
      setActiveView(isFamilyMap ? 'card' : 'list');
    }
  }, [location]);

  const onClickPDF = () => {
    if (activeView === 'card') {
      return ErrorNotification('PDF only available for list view');
    } else {
      SendTrackingEvent(
        invocationId,
        'click',
        'create_pdf_report',
        'household_members',
        {
          isConsumer: isConsumer,
        }
      );
      return DownloadReport({
        value: 'members',
        hasOptions: false,
      });
    }
  };

  const viewOptions = [
    {
      value: 'list',
      label: 'List View',
      image: bars,
      dataTag: 'list-view',
    },
    {
      value: 'card',
      label: 'Map View',
      image: cardGrid,
      dataTag: 'family-map-view',
    },
  ];

  const sendMemberViewTrackingEvent = (verb, data) => {
    const isList = data?.view === 'list';
    const source = isList ? null : 'household_family_map';
    return SendTrackingEvent(invocationId, 'click', verb, source, data);
  };

  const toggleMemberView = (value) => {
    const isFamilyMap = value === 'card';
    navigate(
      `/household/${householdId}/relationships${isFamilyMap ? '/map' : ''}`
    );
    sendMemberViewTrackingEvent('members_toggle_view', {
      view: value,
    });
  };

  return (
    <PageContainer>
      <PageHeader
        id="member-heading"
        content={{
          ...(activeView === 'list'
            ? headerContent.members
            : headerContent.family),
          showButton: showPDF,
        }}
        buttonFunction={onClickPDF}
        showViews={hasFamilyMap}
        activeView={activeView}
        views={viewOptions}
        toggleView={(value) => {
          toggleMemberView(value);
        }}
        margin={isFamilyMap && '25px 40px 5px 40px'}
        subContentMargin="10px 5px 0 0"
      />
      <Outlet context={{ householdId }} />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

HouseholdMembers.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
  showPDF: PropTypes.bool,
};

export default HouseholdMembers;
