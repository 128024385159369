/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { Button, LoadingAnimation, TargetMapPercentCircle } from '../';
import {
  getTargetMaps,
  toggleShowNewTargetMapModal,
  updateTargetMapSummaryValue,
} from '../../store/actions';
import {
  bullseye,
  bullseyeSolid,
  chevronDownDark,
  eyeClosedDark,
  eyeSolid,
  // launchLink,
  pin,
  pinSolid,
  targetIllustration,
} from '../../assets';
import {
  boxShadows,
  colors,
  fonts,
  messageColors,
} from '../../styles/variables';
import {
  FormatCurrency,
  HtmlToString,
  IsNegative,
  SendTrackingEvent,
} from '../../utils';
import {
  FlexCenterAll,
  FlexCenterEnd,
  FlexCenterStart,
} from '../../styles/library/layoutStyles';

const TargetMapSidebar = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params?.id;
  const { currency, showTargetMaps, targetMaps, isStencil, invocationId } =
    useSelector((state) => ({
      currency: state.households.householdCurrency,
      showTargetMaps: state.user.showTargetMaps,
      targetMaps: state.targetMaps.targetMaps,
      isStencil: state.households.isStencil,
      invocationId: state.configs.invocationId,
    }));
  const [loadingTargetMaps, setLoadingTargetMaps] = useState(true);
  const [targetMapList, setTargetMapList] = useState([]);
  const [filterTargetMapList, setFilterTargetMapList] = useState([]);
  const [hasNoTargetMaps, setHasNoTargetMaps] = useState(false);
  const [activeFilter, setActiveFilter] = useState('all');
  const [focusedTargetMap, setFocusedTargetMap] = useState();

  useEffect(() => {
    if (showTargetMaps !== null) {
      if (showTargetMaps) {
        dispatch(getTargetMaps(householdId));
      } else {
        setLoadingTargetMaps(false);
      }
    }
  }, [showTargetMaps]);

  useEffect(() => {
    if (targetMaps) {
      targetMaps.map((targetmap) => {
        targetmap.name = HtmlToString(targetmap.name);
        targetmap.selected = false;
        return targetmap;
      });
      setTargetMapList(targetMaps.sort((a, b) => b.priority - a.priority));
      setFilterTargetMapList(
        targetMaps.sort((a, b) => b.priority - a.priority)
      );
      resetFilters();
      setLoadingTargetMaps(false);
      setHasNoTargetMaps(targetMaps.length === 0);
    }
  }, [targetMaps]);

  useEffect(() => {
    if (activeFilter && !loadingTargetMaps) {
      let activeFilterTargetMaps = [...targetMapList];
      if (activeFilter !== 'all') {
        activeFilterTargetMaps = activeFilterTargetMaps.reduce((acc, tm) => {
          if (tm[activeFilter]) {
            return [...acc, tm];
          }
          return acc;
        }, []);
      }

      setFilterTargetMapList(activeFilterTargetMaps);
    }
  }, [activeFilter, loadingTargetMaps]);

  useEffect(() => {
    if (focusedTargetMap) {
      console.log('focusedTargetMap exists', focusedTargetMap);
    } else if (focusedTargetMap === null) {
      console.log('focusedTargetMap is null, so remove focus styling');
    }
  }, [focusedTargetMap]);

  const filters = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'active',
      label: 'Targets',
    },
    {
      value: 'pinned',
      label: 'Pinned',
    },
  ];

  const resetFilters = () => {
    setActiveFilter('all');
  };

  const modifyTargetMap = (targetMap, action) => {
    if (action === 'pin') {
      dispatch(
        updateTargetMapSummaryValue(
          householdId,
          targetMap,
          {
            pinned: !targetMap.pinned,
          },
          `Target-Map ${targetMap.pinned ? 'Unpinned' : 'Pinned'}`
        )
      );
      SendTrackingEvent(invocationId, 'click', 'targetmap_pin', null, {
        targetmap: targetMap.id,
        pinned: !targetMap.pinned,
      });
    }
    if (action === 'active') {
      dispatch(
        updateTargetMapSummaryValue(
          householdId,
          targetMap,
          {
            active: !targetMap.active,
          },
          `Target-Map ${targetMap.active ? 'Inactive' : 'Active'}`
        )
      );
      SendTrackingEvent(invocationId, 'click', 'targetmap_active', null, {
        targetmap: targetMap.id,
        active: !targetMap.active,
      });
    }
  };

  const focusTargetMapOnClick = (targetMap) => {
    if (targetMap.id === focusedTargetMap) {
      setFocusedTargetMap(null);
    } else {
      setFocusedTargetMap(targetMap.id);
    }
  };

  const fields = [
    {
      name: 'return_rate',
      label: 'Expected Net Return on Capital',
      type: 'number',
      suffix: '%',
    },
    {
      name: 'capital_net',
      label: 'Present Capital Deficit',
      type: 'currency',
    },
    {
      name: 'contribution_needed',
      label: 'Additional Contributions to Fund',
      type: 'currency',
      suffix: '/mo',
      checkAvailable: true,
    },
    {
      name: 'inflation_rate',
      label: 'Annual Increase of Contributions',
      type: 'number',
      suffix: '%',
    },
    {
      name: 'duration',
      label: 'Duration of Contributions',
      type: 'number',
      suffix: 'yrs',
    },
  ];

  const generateDisplayLabel = (field, targetMap) => {
    if (field.name === 'capital_net') {
      return IsNegative(targetMap.capital_net)
        ? 'Present Capital Deficit'
        : 'Present Capital Surplus';
    }
    if (field.name === 'contribution_needed') {
      return IsNegative(targetMap.capital_net)
        ? 'Additional Contributions to Fund'
        : 'Surplus Contributions';
    }
    return field.label;
  };

  const checkAvailable = (field, targetMap) => {
    return (
      field.checkAvailable &&
      Math.abs(Math.round(targetMap.contribution_needed)) ===
        Math.abs(Math.round(targetMap.capital_net))
    );
  };

  const expandTargetMap = (targetMap) => {
    setFilterTargetMapList(
      filterTargetMapList.map((tm) => {
        if (tm.id === targetMap.id) {
          tm.isExpanded = !tm.isExpanded;
        }
        return tm;
      })
    );
  };

  return (
    <>
      {loadingTargetMaps ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : !showTargetMaps || isStencil ? (
        <EmptyContainer>
          <NotFoundImage
            src={targetIllustration}
            alt="No Target-Maps"
            data-image="no-target-maps"
          />
          <NotFoundText>
            {isStencil
              ? 'Target-Maps are not available for Stencil Households'
              : `Contact support to have Target-Maps enabled for your account.`}
          </NotFoundText>
        </EmptyContainer>
      ) : !hasNoTargetMaps ? (
        <>
          <FiltersContainer>
            {filters.map((filter, index) => {
              return (
                <FilterOption
                  key={index}
                  onClick={() => setActiveFilter(filter.value)}
                  $isActive={filter.value === activeFilter}
                >
                  <FilterName>{filter?.label}</FilterName>
                </FilterOption>
              );
            })}
          </FiltersContainer>
          <TargetMapListContainer>
            {filterTargetMapList.map((targetMap) => {
              return (
                <TargetMapContainer
                  key={targetMap.id}
                  $isExpanded={targetMap.isExpanded}
                  $isFocused={
                    focusedTargetMap ? targetMap.id === focusedTargetMap : true
                  }
                >
                  <ActionsContainer>
                    <ActionItem
                      onClick={() => focusTargetMapOnClick(targetMap)}
                    >
                      <ActionItemIcon
                        src={
                          targetMap.id === focusedTargetMap
                            ? eyeSolid
                            : eyeClosedDark
                        }
                        alt={
                          targetMap.id === focusedTargetMap
                            ? 'focused'
                            : 'unfocused'
                        }
                        data-image={
                          targetMap.id === focusedTargetMap
                            ? `focused-${targetMap.id}`
                            : `unfocused-${targetMap.id}`
                        }
                        data-focused={targetMap.id === focusedTargetMap}
                        title={
                          targetMap.pinned
                            ? 'Show Related Financials'
                            : 'Stop Showing Related Financials'
                        }
                        $width={'14px'}
                        $active={targetMap.id === focusedTargetMap}
                      />
                    </ActionItem>
                    {/* <ActionItemLink
                      to={`/household/${householdId}/targetmap/${targetMap.id}`}
                    >
                      <ActionItemIcon
                        src={launchLink}
                        alt={'link'}
                        data-image={`link-${targetMap.id}`}
                        data-id={targetMap.id}
                        title={'View Target-Map'}
                      />
                    </ActionItemLink> */}
                    <ActionItem
                      onClick={() => modifyTargetMap(targetMap, 'pin')}
                    >
                      <ActionItemIcon
                        src={targetMap.pinned ? pinSolid : pin}
                        alt={targetMap.pinned ? 'pinned' : 'pin'}
                        data-image={`pin-${targetMap.id}`}
                        data-pinned={targetMap.pinned}
                        title={
                          targetMap.pinned
                            ? 'Unpin Target-Map'
                            : 'Pin Target-Map'
                        }
                        $margin="0 0 -1px 0"
                        $active={targetMap.pinned}
                      />
                    </ActionItem>
                    <ActionItem
                      $margin="0 15px 0 0"
                      onClick={() => modifyTargetMap(targetMap, 'active')}
                    >
                      <ActionItemIcon
                        src={targetMap.active ? bullseyeSolid : bullseye}
                        alt={targetMap.active ? 'active' : 'inactive'}
                        data-image={`target-${targetMap.id}`}
                        data-active={targetMap.active}
                        title={
                          targetMap.active
                            ? 'Deselect as Target'
                            : 'Select as Target'
                        }
                        $active={targetMap.active}
                      />
                    </ActionItem>
                  </ActionsContainer>
                  <TargetMapContent $isExpanded={targetMap.isExpanded}>
                    <MainContent>
                      <PercentageContainer
                        to={`/household/${householdId}/targetmap/${targetMap.id}`}
                      >
                        <TargetMapPercentCircle
                          targetMap={targetMap}
                          width={'70px'}
                          isList={true}
                        />
                      </PercentageContainer>
                      <NameContainer>
                        <TargetMapName>
                          {HtmlToString(targetMap.name)}
                        </TargetMapName>
                      </NameContainer>
                    </MainContent>
                    <ToggleDetailsContainer
                      onClick={() => expandTargetMap(targetMap)}
                      $isExpanded={targetMap.isExpanded}
                    >
                      <img src={chevronDownDark} alt="down" />
                    </ToggleDetailsContainer>
                  </TargetMapContent>
                  {targetMap.isExpanded && (
                    <DetailsContainer>
                      {fields.map((field, index) => {
                        return (
                          <FieldLineItem
                            key={index}
                            $last={fields.length - 1 === index}
                          >
                            <FieldLabel>
                              {generateDisplayLabel(field, targetMap)}
                            </FieldLabel>
                            <FieldValue
                              style={
                                IsNegative(targetMap[field.name]) &&
                                !checkAvailable(field, targetMap)
                                  ? { color: messageColors.error }
                                  : null
                              }
                            >
                              {checkAvailable(field, targetMap) ? (
                                'N/A'
                              ) : (
                                <>
                                  {field.type === 'number'
                                    ? targetMap[field.name]
                                    : FormatCurrency(
                                        Math.round(targetMap[field.name]),
                                        currency
                                      )}
                                  {field.suffix ? field.suffix : null}
                                </>
                              )}
                            </FieldValue>
                          </FieldLineItem>
                        );
                      })}
                    </DetailsContainer>
                  )}
                </TargetMapContainer>
              );
            })}
            {filterTargetMapList.length === 0 && (
              <EmptyContainer>
                <NotFoundImage
                  src={targetIllustration}
                  alt="No Target-Maps"
                  data-image="no-target-maps"
                />
                <NotFoundText>
                  No Target-Maps match selected filters
                </NotFoundText>
              </EmptyContainer>
            )}
          </TargetMapListContainer>
        </>
      ) : (
        <EmptyContainer>
          <NotFoundImage src={targetIllustration} alt="No Target-Maps" />
          <NotFoundText>No Target-Maps</NotFoundText>
          <Button
            text="New Target-Map"
            onClick={() => dispatch(toggleShowNewTargetMapModal(true))}
          />
        </EmptyContainer>
      )}
    </>
  );
};

const ToggleDetailsContainer = styled.button`
  position: absolute;
  right: 15px;
  bottom: 3px;
  bottom: ${(props) => (props.$isExpanded ? '13px' : '3px')};
  ${FlexCenterEnd};
  padding: 0;
  margin: 0;
  border: none;
  img {
    width: 12px;
    height: 12px;
    transform: ${(props) => (props.$isExpanded ? 'rotate(180deg)' : null)};
  }
  &:hover {
    opacity: 0.8;
    transform: scale(1.01);
  }
`;

const FieldLineItem = styled.div`
  ${FlexCenterStart};
  border-bottom: ${(props) =>
    props.$last ? null : `1px solid ${colors.lightGrey}`};
  padding ${(props) => (props.$last ? '10px 10px 5px 10px' : '10px')};
  min-height: 24px;
`;

const FieldLabel = styled.p`
  flex: 1 1 auto;
  font-size: 11px;
`;

const FieldValue = styled.div`
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  ${FlexCenterStart};
`;

const DetailsContainer = styled.div`
  background: ${colors.lighterGrey};
  margin: 0 0 -10px 0;
  padding: 10px 15px;
  flex: 1 1 auto;
`;

const ActionItem = styled.div`
  flex: 0 0 auto;
  cursor: pointer;
  margin: ${(props) => (props.$margin ? props.$margin : '0 8px 0 0')};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  &:hover {
    opacity: 0.8;
  }
`;

// const ActionItemLink = styled(ActionItem).attrs({
//   as: Link,
// })``;

const ActionItemIcon = styled.img`
  width: ${(props) => (props.$width ? props.$width : '12px')};
  height: ${(props) => (props.$height ? props.$height : '12px')};
  margin: ${(props) => (props.$margin ? props.$margin : null)};
  ${'' /* transform: ${(props) => (props.$active ? `scale(1.1)` : null)}; */}
`;

const ActionsContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  ${FlexCenterEnd}
  z-index: 1;
`;

const MainContent = styled.div`
  ${FlexCenterAll}
`;
const NameContainer = styled.div``;

const PercentageContainer = styled(Link)`
  display: block;
  margin-right: 15px;
  flex: 0 0 auto;
  width: 65px;
  height: 60px;
  color: ${colors.darkGrey};
  ${FlexCenterAll};
`;

const TargetMapContent = styled.div`
  padding: 15px 18px;
  padding: ${(props) =>
    props.$isExpanded ? '15px 18px 25px 18px' : '15px 18px'};
  display: flex;
  justify-content: start;
  position: relative;
  box-shadow: ${(props) =>
    props.$isExpanded ? `0 4px 6px -2px rgba(0, 0, 0, 0.1)` : null};
`;

const TargetMapContainer = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  margin-bottom: 10px;
  padding: 0 0 10px 0;
  display: flex;
  align-items: stretch;
  position: relative;
  flex-direction: column;
  filter: ${(props) => (props.$isFocused ? null : `grayscale(1)`)};
  opacity: ${(props) => (props.$isFocused ? null : `.5`)};
`;

const TargetMapName = styled.p`
  font-size: 12px;
  font-weight: ${fonts.semiBold};
`;

const TargetMapListContainer = styled.div`
  margin-bottom: 40px;
  min-height: 300px;
`;

const LoadingContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const FiltersContainer = styled.div`
  padding: 15px 15px;
  margin-bottom: 10px;
  ${FlexCenterAll};
  border-bottom: 1px solid ${colors.lightGrey};
  justify-content: space-around;
  gap: 10px;
  position: sticky;
  top: 0;
  background: ${colors.white};
  z-index: 2;
`;

const FilterOption = styled.div`
  cursor: pointer;
  background: ${(props) =>
    props.$isActive ? colors.darkGrey : colors.lightGrey};
  color: ${(props) => (props.$isActive ? colors.white : colors.darkGrey)};
  box-shadow: ${(props) =>
    props.$isActive ? boxShadows.boxShadowLight : null};
  padding: 6px;
  font-size: 10px;
  border-radius: 20px;
  ${FlexCenterAll}
  flex: 1 1 33%;
  &:hover {
    transform: scale(1.02);
    opacity: 0.8;
  }
`;

const FilterName = styled.div`
  font-weight: ${fonts.semiBold};
`;

const EmptyContainer = styled.div`
  ${FlexCenterAll};
  flex-direction: column;
`;

const NotFoundImage = styled.img`
  max-height: 430px;
  margin: 40px 20px 20px 20px;
`;

const NotFoundText = styled.p`
  margin: 5px 20px 15px 20px;
  font-size: 20px;
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

export default TargetMapSidebar;
